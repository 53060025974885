@import './fonts.scss';

body {
    font-family: 'Roboto', sans-serif;
    background: #171b21;
    color: #FFFFFF;
}

h1, h2, h3, h4, h5 {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    color: #FFFFFF;
}