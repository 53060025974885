.aboutImage {
    margin: 0 auto;
    max-width: 100%;
}

.container {
    width: 800px;
    max-width: 90%;
    margin: 0 auto;
    .row {
        .col {
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            padding-bottom: 20px;
            .link {
                color: #fff;
            }
        }
    }
}

.install {
    display: block;
    min-width: 150px;
    text-align: center;
    text-decoration: none;
    color: #FFFFFF;
    background: #000;
    border: 2px solid #000;
    outline: none;
    border-radius: 20px;
    margin: 20px auto 0 auto;
    padding: 12px 40px;
    transition: all 0.25s ease;
    &:hover {
        background: #FFFFFF;
        color: #000000;
    }
}