body {
    margin: 0;
    padding: 0;
}

.search-bar {
    padding: 10px;
    margin: 0 auto 40px auto;
    display: block;
    border: 1px solid rgb(110, 110, 110);
    border-radius: 100px;
    min-width: 200px;
    &::placeholder {
        text-align: center; 
    }
}

.not-found {
    text-align: center;
}

.spinner {
    border: 4px solid rgb(100, 100, 100);
    border-left-color: #000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

.movies {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    list-style-type: none;
    max-width: 90%;
    margin: 0 auto;
    padding: 0;
    .single-movie {
        height: 520px;
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: 30px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        transition: all 0.25s ease;
        @media only screen and (min-width: 1100) {
            width: calc(33% - 30px);
        }
        @media only screen and (min-width: 1400px) {
            width: calc(25% - 30px);
        }
        @media only screen and (min-width: 1600px) {
            width: calc(20% - 30px);
        }

        &:hover {
            transform: translate3d(0,-10px,0);
        }
        .movie-card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
            background: #1a2330;
            padding-bottom: 20px;
            .img-container {
                overflow: hidden;
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 10px;
                img {
                    margin: 0;
                    padding: 0;
                    transform: scale(1.075);
                }
            }
            h3 {
                display: inline-block;
                padding: 0;
                margin: 0;
            }
        }
    }
}