.movie-info {
    display: flex;
    flex-direction: row;
    width: 800px;
    max-width: 90%;
    margin: 0 auto;

    
    @media only screen and (max-width: 640px) {
        flex-direction: column;
    }
    img {
        margin-right: 20px;
        @media only screen and (max-width: 640px) {
            margin: 0 auto;
        }
    }
    .movie-copy {
        @media only screen and (max-width: 640px) {
            h2, span, p {
                display: block;
                text-align: center;
            }
        }
    }
}

.start-over {
    display: block;
    text-align: center;
    text-decoration: none;
    color: #FFFFFF;
    background: rgb(0, 0, 0);
    width: 100px;
    border: 2px solid black;
    border-radius: 20px;
    margin: 40px auto 0 auto;
    padding: 8px 40px;
    transition: all 0.25s ease;
    &:hover {
        background: #FFFFFF;
        color: #000000;
    }
}

.loading {
    text-align: center;
}

.spoiler {
    cursor: pointer;
    filter: blur(5px);
    animation-name: blurring;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}

.unblurred {
    filter: none;
    cursor: default;
    animation-name: unset;
}

@keyframes blurring {
    0% {filter: blur(5px);}
    50% {filter: blur(10px);}
    100% {filter: blur(5px);}
  }