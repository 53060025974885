footer {
    position: fixed;
    padding: 10px;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    background: #0a0f16;
    a {
        text-decoration: none;
        color: #FFFFFF;
    }
    .aboutLink {
        position: absolute;
        right: 50px;
    }
  }