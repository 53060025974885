header {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    color: #FFFFFF;
    img {
        filter: invert(100%);
    }
    a {
        text-decoration: none;
        color: #FFFFFF;
    }
    img {
        margin-right: 10px;
    }
    h1 {
        display: inline;
    }

}